* {
    font-family: Arial, Helvetica, sans-serif ;
    overflow: visible ;
    /* outline: 0.1px solid rgb(153, 0, 0); */
}

.dashboard {
    display: flex;
    flex-direction: column;
    align-items:flex-start ;
    height: 98vh;
}

.btn-restart{
    background-color: #E36F1E;
    box-shadow: -2px -2px 2px #E36F1E, 2px 2px 6px #1C1D30;
    border: 2px solid #E36F1E;
    border-radius: 25px;
    color: #fff;
    font-size: 1.2rem;
    padding: 0.5rem 1rem;
    position: absolute;
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);
    }

.denuncia {
    z-index: 999;
    margin-top: 1rem;
    position: 'absolute';
    text-align: center;
}
.logo {
    position: 'absolute';
    top: 0;
    left: 0 ;
    scale: 0.6;
}

.speech-bubble-2 {
width: 30%;
margin-left: 0;
margin-top: 0;
}
.speech-bubble-2__text {
    text-align: right;
    font-size: 0.1em;
    font-weight: 600;
    text-shadow:#fff;
}
.anim01 {
    min-height: 10%;
    max-height: 40%;
    padding-left: 1em;
    padding-top: 20%;
}
.btn-2 {
    border: 2px solid #1C1D30;
    background-color: white;
    color: #1C1D30;
    border-radius: 20px;
    padding: 1rem;
    font-size: 1.6rem;
    margin-left: 25rem;
    margin-top: -40rem;
    text-align: center;
    box-shadow: -2px -2px 2px #E36F1E, 2px 2px 6px #1C1D30;
    text-shadow: 0px 0px 3px #E36F1E;
    font-weight: 750;
}

.btn-2:hover {
    border: 2px solid #E36F1E;
    background-color: violet;
    color: white;
}

.btn__container {
    display: grid;
    place-items: center;
    margin-top: -10rem;
}
/* Estilos para modo oscuro */
.dark-mode {
    background-color: #fff; /* Color de fondo oscuro */
    fill: #fff; /* Color de relleno blanco para SVG */
  }
  
@media only screen and (max-width: 1024px) {
    * {
        font-family: 'Fira Sans', sans-serif, Arial, Helvetica, sans-serif ;
        margin: 0;
    }
    .dashboard {
        overflow: hidden;
    }
    .speech-bubble-reversed {
        margin-top: 8rem;
        display: flex;
        flex-direction: column;
        justify-content:flex-end;
        align-items: flex-end;
        margin-left: 55vw;
        width: 45vw;
        z-index: 3;
    }

    .row {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
    
    .banner__container svg {
        will-change: auto;
        width: 100vw;
        margin-top: 0;
        height: auto;
        display: block;
        position: relative;
        top: -2rem;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
    }
    
    .footer__container {
        display: block;
        position: fixed;
        width: 100vw;
        height: 30vw;
        left: 50%;
        transform: translateX(-50%);
        z-index: -1;
        margin: 0;
        bottom: 0;
        padding: 0;
        overflow: hidden;
    }
    
    .logo1 {
        position: relative;
        display: flex;
        z-index: 2;
        flex-direction: row;
        justify-content: center;
    }
    .logo__container {
        position: relative;
        display: flex;
        z-index: 3;
        flex-direction: row;
        justify-content: center;
        margin: -20rem 0 0 0;
    }
    .logo{
        width: 500px;
    }
    .btn {
        background-color: #f4b122;
        color: #1C1D30;
        border-radius: 6rem;
        padding: 1.2rem;
        font-size: 1.2rem;
        text-align: center;
        font-weight: 750;
        border: 0;
        padding: 1rem;
        margin: 1rem;
        margin-top: 6rem;
    }

    html,
    body {
        height: 100%;
    }
    .dashboard__container {
        min-height: 100%;
    }
    .banner__container{
        will-change: auto;
    }
    .footer__container {
        position: absolute;
        bottom: 0;
        width: 100%;
        z-index: 1;
    }
    .speech-bubble {
        width: 40vh;
        margin-left: 2vw;
        margin-top: 2vh;
    }
    .anim02 {
        margin-top: -15rem;
        display: flex;
        flex-direction: column;
        justify-content:flex-end;
        align-items: flex-end;
        margin-left: 5vw;
        width: 60vw;
    }
    .anim03 {
        transform: scale(-2,2);
        margin-top: -9rem;
        display: flex;
        flex-direction: column;
        justify-content:flex-end;
        align-items: flex-end;
        margin-left: 30vw;
        width: 30vw;
    }
    .anim04 {
        display: flex;
        flex-direction: column;
        justify-content:flex-end;
        align-items: flex-end;
        margin-top: 2rem;
        margin-left: 3vw;
        width: 60vw;
        z-index: 2;
/*
        transform: scale(1.4,1.4);
        margin-top: -10rem;
        display: flex;
        flex-direction: row;
        margin-left: 10vw;
        position: fixed;
        width: 35vw;
        z-index: 9;
        top:65%;
         */
    }
    .anim04-3-small {
        position: fixed;
        top: 15%;
        right: 2rem;
        width: 30vw;
        z-index: 1;
        opacity: 1;
        transition: opacity 0.3s ease-in, width 0.3s ease-in; /* Agregado */
    }

    .anim04-3.hide {
    opacity: 0;
    }
    .anim04-3 {
        display: flex;
        flex-direction: column;
        justify-content:flex-end;
        align-items: flex-start;
        margin-top: -1rem;
        margin-left: 3rem;
        width: 50vw;
        z-index: 2;
        opacity: 1;
        transition: opacity 0.3s ease-out;
    }
    .anim04-2 {
        display: flex;
        flex-direction: column;
        justify-content:flex-end;
        align-items: flex-start;
        margin-left: 3rem;
        width: 70vw;
        scale: 2;
/* 
        transform: scale(1.4,1.4);
        margin-top: 4rem;
        display: flex;
        flex-direction: row;
        margin-left: 10vw;
        width: 35vw;
        z-index: 7;
         */
    }
    .anim05 {
        transform: scale(1.4,1.4);
        margin-top: -10rem;
        display: flex;
        flex-direction: column;
        justify-content:flex-end;
        align-items: flex-end;
        margin-left: 17vw;
        width: 30vw;
        z-index: 1;
    }
    .alert-message-box {
        position: fixed;
        font-size: 2rem;
        text-align: center;
        top: 75%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1000;
        background-color: #f4b223;
        color: #000;
        padding: 0.75rem 1.25rem;
        border: 1px solid #f5c6cb;
        border-radius: 25px;
        box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.5);
        height: 20%;
        width: 60%;
        display: flex;
        align-items: center;
        z-index: 999999;
    }
    .alert-message-button {
        font-size: 2rem;
        background-color: #E36F1E;
        box-shadow: -2px -2px 2px #E36F1E, 2px 2px 6px #1C1D30;
        border: 2px solid #E36F1E;
        border-radius: 25px;
        color: #fff;
        padding: 0.5rem 1rem;
        position: absolute;
        bottom: 1rem;
        left: 50%;
        transform: translateX(-50%);
        margin-top: 2rem;
    }
    
    .speech-bubble-3 {
        position: relative;
        position: fixed;
        width: 23%;
        top: 60%;
        left: 50%;
        z-index: 20;
    }
    .speech-bubble-4 {
        position: relative;
        width: 45%;
        margin-left: 45vw;
        margin-top: 6rem;
        z-index: 5;
    }
    .speech-bubble-4__text {
        text-align: center;
        font-size: 1em;
        font-weight: 600;
        text-shadow:#fff;
    }
    .speech-bubble-5 {
        position: relative;
        width: 45%;
        margin-left: 2vw;
        margin-top: 2rem;
        margin-bottom: -10rem;
        z-index: 5;
    }
    .input__container2-2 {
        position: relative;
        display: flex;
        z-index: 2;
        flex-direction: row;
        justify-content: center;
        align-items: center; /* Añadido para centrar verticalmente */
        height: 40vh; /* Añadido para ocupar la altura completa de la pantalla */
        width: 700px;
    }
    .input__container {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-right: 10rem;
        margin-top: -3rem;
        z-index: 3;
    }
    .btn__container2 {
        position: relative;
        display: flex;
        z-index: 2;
        flex-direction: row;
        justify-content: center;
        margin-top: 2rem;
    }
    .btn__container3 {
        position: relative;
        z-index: 2;
        justify-content: center;
        margin-top: 8rem;
        display: grid;
        place-items: center;
    }
    .btn__container4 {
        position: fixed;
        z-index: 10;
        top: 75%;
        right: 5%;
        display: flex;
        justify-content:flex-end;
        outline: none;
    }
    .btn__container5 {
        position: relative;
        z-index: 3;
        margin-top: 8%;
        display: flex;
        justify-content: center;
        outline: none;
    }
    .btn__container6 {
        position: relative;
        z-index: 3;
        margin-top: 5%;
        display: flex;
        justify-content: center;
        outline: none;
    }
    .btn__container4 button {
        border: none;
    }
    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path fill="%23f4b122" d="M4.566 6.734l3.794 4.882c.364.469 1.07.469 1.434 0l3.794-4.882A1 1 0 0 0 13.208 5H4.792a1 1 0 0 0-.226 1.734z"/></svg>');
        background-repeat: no-repeat;
        background-position: right 0.7em top 50%;
        background-size: 1.5em auto;
        border: 4px solid #f4b122;
        border-radius: 45px;
        width: 120%;
        padding: 20px;
        text-align: center;
        font-weight: bold;
        font-size: 1.4rem;
        color: black;
    }
    
    select::after {
        content: "";
        width: 0.8em;
        height: 0.8em;
        border-left: 0.2em solid #f4b122;
        border-bottom: 0.2em solid #f4b122;
        transform: rotate(-45deg);
        position: absolute;
        right: 1em;
        top: 50%;
        margin-top: -0.4em;
    }
    .input__fields__container{
        margin-top: 3rem;
    }
    .input__fields__container2{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .input__fields__container3{
        display: flex;
        flex-direction: column;
        align-items:flex-end;
        
        z-index: 7;
    }
    
    .input__fields__container2 label{
        margin-top: 3rem;
    }

    .input__container2 {
        position: relative;
        margin-top: 1.5rem;
        z-index: 5;
        margin-left: -3rem;
    }
    .space {
        padding-top: 10rem;
    }
    .row2{
        display: flex;
        flex-direction: row;
    }
    .input__container3 label{
        font-size: 1.2rem;
        font-weight: bold;
        color: black;
        /* display: block; */
        margin-bottom: 2px;
        margin-left: 5rem;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .input__container2 label {
        font-size: 1.2rem;
        font-weight: bold;
        color: black;
        /* display: block; */
        margin-bottom: 2px;
        margin-left: 5rem;
        display: flex;
        flex-direction: column;
        align-items: left;
    }
    .input__container2 input {
        border: 4px solid #f4b122;
        border-radius: 45px;
        width: 50%;
        padding: 15px;
        text-align: center;
        font-size: 1.2rem;
        font-weight: bold;
        color: black;
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
    }
    
    .input__container2 input:focus {
        outline: none;
    }
    .input-container input::placeholder {
        color: #000;
    }
    
    .input__container2 select {
        border: 4px solid #f4b122;
        /* margin-bottom: 1.5rem; */
        border-radius: 45px;
        width: 50%;
        padding: 1.5rem;
        text-align: center;
        font-size: 1rem;
        font-weight: bold;
        color: black;
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path fill="%23f4b122" d="M4.566 6.734l3.794 4.882c.364.469 1.07.469 1.434 0l3.794-4.882A1 1 0 0 0 13.208 5H4.792a1 1 0 0 0-.226 1.734z"/></svg>');
        background-repeat: no-repeat;
        background-position: right 0.7em top 50%;
        background-size: 1.5em auto;
    }
    .input__container2 textarea {
            border: 4px solid #f4b122;
            margin-top: -1.5rem;
            border-radius: 45px;
            width: 45vw;
            height: 15vh;
            padding: 1.5rem;
            text-align: center;
            font-size: 1.2rem;
            font-weight: bold;
            color: black;
            appearance: none;
        }
    .input__container2 select:focus {
        outline: none;
    }
    
    .input__container2 select::after {
        content: "";
        width: 0.8em;
        height: 0.8em;
        border-left: 0.2em solid #f4b122;
        border-bottom: 0.2em solid #f4b122;
        transform: rotate(-45deg);
        position: absolute;
        right: 1em;
        top: 50%;
        margin-top: -0.4em;
    }
    .emoji__container{
        display: flex;
        width: 10vw;
    }

    .text__container input:focus {
        outline: none;
        
    }
    .text__container textarea {
        border: 4px solid #f4b122;
            border-radius: 45px;
            width: 45vw;
            height: 15vh;
            padding: 1.5rem;
            text-align: center;
            font-size: 1.4rem;
            font-weight: 900;
            color: #565656;
            appearance: none;
            margin: 2rem;
            margin-left: -2rem;
    }
    
    .anim01-02 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: cemter;
        margin-left: 20vw;
        margin-top: 15vh;
        margin-bottom: -3vh;
        width: 60vw;
/* 
        transform: scale(1.5,1.5);
        margin-top: 4rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: 25vw;
        width: 45vw;
         */
    }
    .btn-3 {
        background-color: #f4b122;
        color: #1C1D30;
        border-radius: 6rem;
        padding: 1rem 4rem 1rem 4rem;
        font-size: 1.6rem;
        text-align: center;
        font-weight: 750;
        border: 0;
        margin-top: 7rem;
        z-index: 8;
    }
    
    .btn:hover {
        background-color: #f4b122;
        color: #ffffff;
    }
    .speech-bubble-6{
        position: relative;
        width: 40%;
        margin-left: 20%;
        margin-top: 3rem;
        margin-bottom: -30%;
        z-index: 5;
    }
    .emoji__container svg {
        filter: saturate(0);
    }
    
    .emoji__container .selected {
        filter: none;
    }
    .recorded-audio {
        display: flex;
        flex-direction: column;
        align-items: center;
        /* width: 300px; */
        position: fixed;
        top: 90%;
        left: 40%;
        transform: translate(-50%, -50%);
        /* background-color: #f4b122; */
        padding: 20px;
        border-radius: 5px;
        z-index: 9999;
        scale: 1.2;
        margin-left: 3rem;
    }
    
    .recorded-audio button {
    margin-top: 10px;
    }
    .audio-recorder{
        margin-top: -8rem;
        margin-right: 4rem;
        position: fixed;
        right: 0;
    }
    .recorded-audio {
        position: fixed;
        top: 58%;
        right: 10%;
        transform: translateY(-50%);
        background-color: #a2a2a2ad;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        border-radius: 25px;
        padding: 1rem;
        z-index: 9999;
    }
    .recording-text {
        font-size: 1.2rem;
        z-index: 999;
        animation: blink 1s linear infinite;
    }
    .titilar {
        animation: titilar 1s infinite;
      }
      
      @keyframes titilar {
        0% {
          opacity: 1;
        }
        50% {
          opacity: 0.5;
        }
        100% {
          opacity: 1;
        }
      }
/* 
    .audio-recorder{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 300px;
        position: fixed;
        top: 65%;
        left: 45%;
        z-index: 9999;
        scale: 1.6;
    } */
    .mute-button {
        background-color: #E36F1E;
        box-shadow: -2px -2px 2px #E36F1E, 2px 2px 6px #1C1D30;
        border: 2px solid #E36F1E;
        border-radius: 25px;
        color: #fff;
        font-size: 1.2rem;
        padding: 0.5rem 1rem;
        position: fixed;
        bottom: 1rem;
        left: 2rem;
        }

    .mute-button2 {
        background-color: #E36F1E;
        box-shadow: -2px -2px 2px #E36F1E, 2px 2px 6px #1C1D30;
        border: 2px solid #E36F1E;
        border-radius: 25px;
        color: #fff;
        font-size: 1.2rem;
        padding: 0.5rem 1rem;
        position: fixed;
        bottom: 1rem;
        left: 2rem;
        z-index: 99999;
        }
.muted {
    box-shadow: inset -2px -2px 2px rgba(255, 255, 255, 0.2),
    inset 2px 2px 6px rgba(0, 0, 0, 0.3);
}
    .denuncia-button {
        position: fixed;
        bottom: 7%;
        left: 50%;
        transform: translateX(-50%);
        background-color: red;
        color: white;
        border-radius: 25px;
        padding: 10px 20px;
        font-size: 16px;
        cursor: pointer;
        z-index: 99999;
    }
    .btn-3:hover, .btn-restart:hover, .denuncia-button:hover {
        box-shadow: inset -2px -2px 2px rgba(255, 255, 255, 0.2),
        inset 2px 2px 6px rgba(0, 0, 0, 0.3);
    }
    
    .selected-emoji-text {
    position: absolute;
    font-size: 1rem;
    font-weight: bold;
    top: -2rem;
    left: 0;
    right: 0;
    margin: auto;
    width: 100%;
    text-align: center;
    }

    .textarea-container {
    position: relative;
    margin-top: 1rem;
    }      
    .disabled {
        opacity: 0.5;
        pointer-events: none;
    }
}