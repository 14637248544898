@import url('https://fonts.googleapis.com/css2?family=Fira+Sans&display=swap');

* {
    font-family: 'Fira Sans', sans-serif, Arial, Helvetica, sans-serif ;
    /* outline: 0.1px solid rgb(153, 0, 0); */
    overflow:hidden ;
    margin: 0;
}
.datos-personales{
    text-align: center;
    margin-top: 1rem;
    padding: 1rem;
    z-index: 999;
}
.version{
    text-align: center;
}
#checkbox{
    margin-top: 1rem;
    margin-left: 2rem;
    scale: 2;
    cursor: pointer;
}
.disabled {
    opacity: 0.5;
}
.datos-personales p {
    position: fixed;
    top: 80%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 85%;
    padding: 1rem;
    background-color: rgba(255, 255, 255, 0.9);
    border: 1px solid gray;
    border-radius: 10px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
    z-index: 999;
    margin: 2rem auto;
    font-size: large ;
}

.dashboard__container {
    justify-content: center;
    align-items: center;
    margin: 0;
}

.row {
    display: flex;
    flex-direction: row;
    justify-content: center
}

.banner__container {
    position: relative;
}
.banner__container svg{
    width: 100vw;
    height: auto;
    margin-top: -16rem;
}

.footer__container {
    display: block;
    position: relative;
    /* width: 100vw; */
    height: auto;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    margin: 0;
    padding: 0;
}

.logo1 {
    z-index: 2;
}
.logo {
    display: flex;
    flex-direction:row-reverse;
    /* scale: 0.6; */
    margin-top: -12rem;
    justify-content:flex-end;
    position: relative;
    display: flex;
    z-index: 2;
    flex-direction: row;
    justify-content:flex-end;
    margin-right: -10rem;
}
.speech-bubble {
width: 40vh;
margin-top: -2rem;
/* margin-left: 45vw;
display: inline-block;
vertical-align: middle; */
}
.speech-bubble__text {
    text-align: right;
    font-size: 0.2em;
    white-space: pre-wrap;
    text-shadow:#fff
}
.anim01 {
    /* scale: 0.6; */
    /* min-height: 10%;
    max-height: 40%; */
    /* padding-left: 1em;
    padding-top: 20%; */
    /*
    margin-top: -15rem;
    */
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center
}
.btn {
    background-color: #f4b122;
    color: #1C1D30;
    border-radius: 6rem;
    padding: 1.2rem;
    font-size: 1.9rem;
    text-align: center;
    font-weight: 750;
    border: 0;
    margin-left: 10rem;
    box-shadow:#575757 .2rem .2rem ;
}

.btn:hover {
    border: 0;
    background-color: #e46f1f;
    color: white;
    box-shadow:#1C1D30 -0.2rem -0.2rem ;
}

@media only screen and (max-width: 1024px) {
    * {
        font-family: 'Fira Sans', sans-serif, Arial, Helvetica, sans-serif ;
        overflow: visible ;
        margin: 0;
    }
    .dashboard__container {
        max-width: 100vw;
        max-height: 100vh;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow:hidden ;
        margin: 0;
    }
    
    .row {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
    
    .banner__container svg {
        width: 100vw;
        height: auto;
        margin-top: 0;
        height: auto;
        display: block;
        position: relative;
        top: -2rem;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
    }
    
    .footer__container {
        display: block;
        position: relative;
        width: 100vw;
        height: auto;
        left: 50%;
        transform: translateX(-50%);
        z-index: 2;
        margin: 0;
        bottom: 0;
        padding: 0;
    }
    
    .logo1 {
        position: relative;
        display: flex;
        z-index: 2;
        flex-direction: row;
        justify-content: center;
    }
    .logo__container {
        position: relative;
        display: flex;
        z-index: 3;
        flex-direction: row;
        justify-content: center;
        margin: -20rem 0 0 0;
    }
    .logo{
        width: 500px;
    }
    .btn {
        background-color: #f4b122;
        color: #1C1D30;
        border-radius: 6rem;
        padding: 1.2rem;
        font-size: 1.2rem;
        text-align: center;
        font-weight: 750;
        border: 0;
        padding: 1rem;
        margin: 1rem;
        margin-top: 6rem;
    }

    html,
    body {
        height: 100%;
    }
    .dashboard__container {
        min-height: 100%;
    }
    .footer__container {
        position: absolute;
        bottom: 0;
        width: 100%;
        z-index: 1;
    }
    .speech-bubble {
        width: 40vh;
        margin-left: 2vw;
        margin-top: 2vh;
    }
    .anim01 {
        margin-top: -15rem;
        display: flex;
        flex-direction: column;
        justify-content:flex-end;
        align-items: flex-end;
        margin-left: 35vw;
        width: 60vw;
    }
    .btn__container {
        position: relative;
        display: flex;
        z-index: 2;
        flex-direction: row;
        justify-content: center;
        margin-top: -7rem;
    }
    .arrow-image {
        width: 100px;
        height: 100px;
        background-color: white;
        }

    .arrow-down {
        position: absolute;
        bottom: 160px;
        right: 4.5rem;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid orange;
        margin: 0 auto;
    }
}